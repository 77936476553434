import { default as birthdaysMUtynSHL6QMeta } from "/opt/build/repo/pages/birthdays.vue?macro=true";
import { default as imagesoKzIqOnhLJMeta } from "/opt/build/repo/pages/images.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as musicKhCiEOm1FzMeta } from "/opt/build/repo/pages/music.vue?macro=true";
import { default as profileNJNbyWXx9rMeta } from "/opt/build/repo/pages/profile.vue?macro=true";
import { default as request_passwordLGY5RFaW7CMeta } from "/opt/build/repo/pages/request_password.vue?macro=true";
import { default as set_passwordPi71RfKLbcMeta } from "/opt/build/repo/pages/set_password.vue?macro=true";
import { default as usersHI5FWnuapYMeta } from "/opt/build/repo/pages/users.vue?macro=true";
export default [
  {
    name: birthdaysMUtynSHL6QMeta?.name ?? "birthdays",
    path: birthdaysMUtynSHL6QMeta?.path ?? "/birthdays",
    meta: birthdaysMUtynSHL6QMeta || {},
    alias: birthdaysMUtynSHL6QMeta?.alias || [],
    redirect: birthdaysMUtynSHL6QMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/birthdays.vue").then(m => m.default || m)
  },
  {
    name: imagesoKzIqOnhLJMeta?.name ?? "images",
    path: imagesoKzIqOnhLJMeta?.path ?? "/images",
    meta: imagesoKzIqOnhLJMeta || {},
    alias: imagesoKzIqOnhLJMeta?.alias || [],
    redirect: imagesoKzIqOnhLJMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/images.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginBXD9sq3T3sMeta?.name ?? "login",
    path: loginBXD9sq3T3sMeta?.path ?? "/login",
    meta: loginBXD9sq3T3sMeta || {},
    alias: loginBXD9sq3T3sMeta?.alias || [],
    redirect: loginBXD9sq3T3sMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: musicKhCiEOm1FzMeta?.name ?? "music",
    path: musicKhCiEOm1FzMeta?.path ?? "/music",
    meta: musicKhCiEOm1FzMeta || {},
    alias: musicKhCiEOm1FzMeta?.alias || [],
    redirect: musicKhCiEOm1FzMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/music.vue").then(m => m.default || m)
  },
  {
    name: profileNJNbyWXx9rMeta?.name ?? "profile",
    path: profileNJNbyWXx9rMeta?.path ?? "/profile",
    meta: profileNJNbyWXx9rMeta || {},
    alias: profileNJNbyWXx9rMeta?.alias || [],
    redirect: profileNJNbyWXx9rMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: request_passwordLGY5RFaW7CMeta?.name ?? "request_password",
    path: request_passwordLGY5RFaW7CMeta?.path ?? "/request_password",
    meta: request_passwordLGY5RFaW7CMeta || {},
    alias: request_passwordLGY5RFaW7CMeta?.alias || [],
    redirect: request_passwordLGY5RFaW7CMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/request_password.vue").then(m => m.default || m)
  },
  {
    name: set_passwordPi71RfKLbcMeta?.name ?? "set_password",
    path: set_passwordPi71RfKLbcMeta?.path ?? "/set_password",
    meta: set_passwordPi71RfKLbcMeta || {},
    alias: set_passwordPi71RfKLbcMeta?.alias || [],
    redirect: set_passwordPi71RfKLbcMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/set_password.vue").then(m => m.default || m)
  },
  {
    name: usersHI5FWnuapYMeta?.name ?? "users",
    path: usersHI5FWnuapYMeta?.path ?? "/users",
    meta: usersHI5FWnuapYMeta || {},
    alias: usersHI5FWnuapYMeta?.alias || [],
    redirect: usersHI5FWnuapYMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/users.vue").then(m => m.default || m)
  }
]