import { defineNuxtPlugin } from "#app"
import * as pkg from "~/package.json"
import * as Sentry from "@sentry/browser"
import { Integrations } from "@sentry/tracing"

export default defineNuxtPlugin((nuxtApp) => {
  const release = `appcia@${pkg.version}`
  const environment = nuxtApp.$config.ENV

  Sentry.init({
    dsn: "https://31acf842d6c947998de8eea121489580@o4504803757785088.ingest.sentry.io/4504803791470592",
    release,
    environment,
    integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
    sampleRate: 1,
    tracesSampleRate: 1,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  })
})
